import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducers list
import tramitsReducer from './reducers/tramitsReducer'
import userReducer from './reducers/userReducer'

// Create deducer all in one
const allReducers = combineReducers({
    tramits: tramitsReducer,
    user: userReducer
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT" || action.type === "UNAUTHORIZED") {
        state = undefined;
    }
    return allReducers(state, action)
}

// Config Middleware for async actions
const thunkHandler = applyMiddleware(thunk);
const composeEnhancers = (typeof window !== 'undefined' && /localhost/.test(window.location.href) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)

// Create the store
const store = createStore(rootReducer, composeEnhancers(thunkHandler))

export default store;
