import React, { lazy, Suspense } from 'react';
import lazyLoaderComponents from '../services/lazyLoaderComponents';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import userStore from './../store/users';
import adminStore from './../store/admin';

// Screens
const UserScreensRoot = lazy(() => lazyLoaderComponents(()=>import('./UserScreens')));
const AdminScreensRoot = lazy(() => lazyLoaderComponents(() => import('./AdminScreens')));

function ScreensRoot() {

    return (
        <Router>
            <Suspense fallback={
                <div style={{ height: "100vh", backgroundColor: "transparent" }} className="d-flex justify-content-center align-items-center flex-column">
                    <div className="spinner-border" role="status"></div>
                    <div ><small>Cargando</small></div>
                </div>
            }>
                <Switch>
                    <Route path="/admin">
                        <Provider store={adminStore}>
                            <AdminScreensRoot />
                        </Provider>
                    </Route>
                    <Route path="/">
                        <Provider store={userStore}>
                            <UserScreensRoot />
                        </Provider>
                    </Route>
                    <Route path="*">
                        <div className="text-center w-100 mt-5 pt-5">
                            <h1 className="mt-5"><b>404</b> Not Found</h1>
                            <h1 className="mt-5">:(</h1>
                        </div>
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    )
}

export default ScreensRoot;
