import axios from 'axios';
import adminStore from './../store/admin';
import userStore from './../store/users';
import { setUnauthorized as adminUnauthorized } from './../store/admin/actions/userAction';
import { setUnauthorized as userUnauthorized } from './../store/users/actions/userAction';
import { AXIS_API_URL } from './constantsService';
import { sendReport } from '../components/ErrorHandler';

/**
 * Axios API request for users make requets
 * @param {String} endpoint // End point of API
 * @param {String} method //post, get, put, delete
 * @param {any} data Data to send
 * @param {boolean} formData if request must be used as formData
 * @param {String} completeUrl URL complete 
 * @param {Function} onUploadProgress function for uploads  
 */
export const AXIOS_REQUEST = (endpoint, method = "get", data = null, formData = true, completeUrl = null, onUploadProgress = () => { }) => {
    var headers = {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("user"))?.token || ""}`,
        'Content-Type': 'application/json'
    }
    let params = null;
    if (method !== "get" && method !== "delete" && formData) {
        headers = {
            ...headers,
            'Process-Data': false,
            "Content-Type": false
        }
    } else if (method === "get" || method === "delete") {
        params = data;
    }

    return axios({
        method,
        url: completeUrl || `${AXIS_API_URL}${endpoint}`,
        data,
        params,
        headers,
        onUploadProgress,
    }).then(resp => {
        return resp?.data
    }).catch(err => {
        if (err.response?.status === 401) {
            if (window.location.href.search(/\/[#]\/nuevo\?/) !== -1) {
                window.location.href = `${window.location.href.split("/#/")[0]}/#/login`;
            }
            userStore.dispatch(userUnauthorized(true));
        } else if (err.response?.status === 500) {
            return sendReport(data, () => ({ error: true }), () => ({ error: true }))
        }

        return { error: true };
    })
}

/**
 * Axios API request for admins make requets
 * @param {String} endpoint // End point of API
 * @param {String} method //post, get, put, delete
 * @param {any} data Data to send
 * @param {boolean} formData if request must be used as formData
 * @param {String} completeUrl URL complete 
 */
export const AXIOS_REQUEST_AD = (endpoint, method = "get", data = null, formData = true, completeUrl = null, onUploadProgress = () => { }) => {
    var headers = {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("user_ad"))?.token || ""}`,
        'Content-Type': 'application/json'
    }
    let params = null;
    if (method !== "get" && method !== "delete" && formData) {
        headers = {
            ...headers,
            'Process-Data': false,
            "Content-Type": false
        }
    } else if (method === "get" || method === "delete") {
        params = data;
    }

    return axios({
        method,
        url: completeUrl || `${AXIS_API_URL}${endpoint}`,
        data,
        params,
        headers,
        onUploadProgress
    }).then(resp => {
        return resp.data
    }).catch(err => {
        if (err.response.status === 401) {
            adminStore.dispatch(adminUnauthorized(true));
        } else if (err.response?.status === 500) {
            return sendReport(data, () => ({ error: true }), () => ({ error: true }))
        }
        // return []
        return { error: true };
    })
}
