import { AXIOS_REQUEST } from './../../../services/axiosService';

// =============================================================
// CONST ACTIONS
export const CHANGE_FILTER = "CHANGE_FILTER";
export const SET_LIST = "SET_LIST";
export const SELECT_DEPENDENCY = "SELECT_DEPENDENCY";
export const SET_DEPENDENCY_LIST = "GET_DEPENDENCY_LIST";

// =============================================================
// FUNCTION ACTIONS
export const changeFilter = payload => ({ payload, type: CHANGE_FILTER });
export const setList = payload => ({ payload, type: SET_LIST });
export const selectDependency = payload => ({ payload, type: SELECT_DEPENDENCY });
export const setDependencyList = payload => ({ payload, type: SET_DEPENDENCY_LIST });

// =============================================================
// ASYNC ACTIONS
/**
 * Get tramit list
 */
export const getList = () => {
    return (dispatch) => {
        return AXIOS_REQUEST(`radicado`)
            .then(resp => {
                dispatch(setList(resp));
                return true;
            }).catch(err => {
            });
    };
};

/**
 * Get tramit information
 * @param {int} payload Radicado id
 */
export const getTramitInformation = (payload) => {
    return (dispatch) => {
        return AXIOS_REQUEST(`radicado/detalle2/${payload}`)
            .then(resp => {
                return resp[0];
            }).catch(err => {
            });
    };
};

/**
 * Delete user tramit 
 * @param {int} payload Radicado id
 */
export const deleteUserTramit = (payload) => {
    return (dispatch) => {
        return AXIOS_REQUEST(`radicado/${payload}`, "delete")
            .then(resp => {
                return resp;
            }).catch(err => {
            });
    };
};

/**
 * Get tramit information
 * @param {object} payload 
 */
export const sendTramit = (payload, progress) => {
    return (dispatch) => {
        return AXIOS_REQUEST(`radicado`, "post", payload, true, null, progress)
            .then(resp => {
                return resp;
            }).catch(err => {
            });
    };
};

/**
 * Get dependency list
 * @param {string} payload todos for get all dependency or null for only actives
 */
export const getDependencyList = (payload) => {
    let _all = !!(payload) && `dependencia/${payload}` || "dependencia";
    return (dispatch) => {
        return AXIOS_REQUEST(_all)
            .then(resp => {
                let ordered = resp.sort((a, b) => (a.orde_dep > b.orde_dep) ? 1 : -1)
                dispatch(setDependencyList(resp))
                return resp;
            }).catch(err => {
            });
    };
};



