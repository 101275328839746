import { AXIS_BASE_URL } from '../../../services/constantsService';
import { AXIOS_REQUEST } from './../../../services/axiosService';

// =============================================================
// CONST ACTIONS
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UNAUTHORIZED = "UNAUTHORIZED";

// =============================================================
// ACTIONS
export const logout = payload => ({ payload, type: LOGOUT });
export const setLogin = payload => ({ payload, type: LOGIN });
export const setUnauthorized = payload => ({ payload, type: UNAUTHORIZED });

// =============================================================
// ASYNC ACTIONS

/**
 * Verify if account is institutional
 * @param {string} user 
 */
export const verifyAccount = (user) => {
    return (dispatch) => {
        return AXIOS_REQUEST(null, "get", null, false, `${AXIS_BASE_URL}apildap/api/ldap/account/${user}`)
    }
}

/**
 * Login function for students
 * @param {object} data {type: number, username: string, password: string}
 */
export const studentLogin = ({ data, callback }) => {
    return (dispatch) => {
        return AXIOS_REQUEST("auth/token", "post", data, false).then(resp => {
            var per = resp.persona.trim().split(" ");
            var user = {
                type: resp.roles,
                token: resp.token,
                email: data.username
            }
            switch (per.length) {
                case 4:
                    user.nombre = `${per[0]} ${per[1]}`
                    user.apellido = `${per[2]} ${per[3]}`
                    break;
                case 3:
                    user.nombre = `${per[0]}`
                    user.apellido = `${per[1]} ${per[2]}`
                    break;

                case 2:
                    user.nombre = `${per[0]}`
                    user.apellido = `${per[1]}`
                    break;

                default:
                    user.nombre = `${per[0]} ${per[1]}`
                    user.apellido = `${per[2]} ${per[3]}`
                    break;
            }
            // return callback().then(token => {
                // return dispatch(verifyCaptchaToken(token)).then(resp => {
                    // if (resp.success) {
                        localStorage.setItem("user", JSON.stringify(user))
                        dispatch(setLogin(user));
                        return resp;
                    // } else {
                    //     resp.error = true;
                    //     return resp;
                    // }
                // })
            // })

        })
    }
}

/**
 * Verify a captcha token
 * @param {string} token captcha token
 */
export const verifyCaptchaToken = token => {
    return (dispatch) => {
        return AXIOS_REQUEST("auth/recaptcha", "post", { token }, false)
    }
}


