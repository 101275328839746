import { AXIS_BASE_URL } from '../../../services/constantsService';
import { AXIOS_REQUEST_AD } from './../../../services/axiosService';

// =============================================================
// CONST ACTIONS
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UNAUTHORIZED = "UNAUTHORIZED";

// =============================================================
// ACTIONS
export const logout = payload => ({ payload, type: LOGOUT });
export const setLogin = payload => ({ payload, type: LOGIN });
export const setUnauthorized = payload => ({ payload, type: UNAUTHORIZED });

// =============================================================
// ASYNC ACTIONS

/**
 * Function for admin login
 * @param {object} data {username: string, password: string} callback:function
 */
export const adminLogin = ({ data, persona }) => {
    return (dispatch) => {
        return AXIOS_REQUEST_AD("auth/operador", "post", data, false).then(resp => {
            if (resp.error === true) return null;
            var user = {
                token: resp.token,
                nombre: persona.given_name,
                foto: persona.picture,
                apellido: persona.family_name,
                email: persona.email
            }
            // switch (per.length) {
            //     case 4:
            //         user.nombre = `${per[0]} ${per[1]}`
            //         user.apellido = `${per[2]} ${per[3]}`
            //         break;
            //     case 3:
            //         user.nombre = `${per[0]}`
            //         user.apellido = `${per[1]} ${per[2]}`
            //         break;

            //     default:
            //         user.nombre = `${per[0]}`
            //         user.apellido = `${per[1]}`
            //         break;
            // }
            localStorage.setItem("user_ad", JSON.stringify(user))
            dispatch(setLogin());
            return true
        })
    };
};
