import {
    SET_PENDING_LIST,
    SET_HISTORY_LIST,
    SET_PENDING_QUERY,
    SET_HISTORY_QUERY,
    HISTORY_LIST_PAGINATION_NUMBER,
    TRAMIT_LIST_PAGINATION_NUMBER
} from '../actions/tramitsAction';

const initialState = {
    tramitsListPage: 0,
    historyListPage: 0,
    pendingList: [],
    pendingQuery: null,
    historyQuery: null,
    historyList: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRAMIT_LIST_PAGINATION_NUMBER:
            return {
                ...state,
                tramitsListPage: action.payload
            };
        case SET_PENDING_QUERY:
            return {
                ...state,
                pendingQuery: action.payload,
                tramitsListPage: 0
            };
        case SET_HISTORY_QUERY:
            return {
                ...state,
                historyQuery: action.payload,
                tramitsListPage: 0
            };

        case HISTORY_LIST_PAGINATION_NUMBER:
            return {
                ...state,
                historyListPage: action.payload
            };

        case SET_HISTORY_LIST:
            return {
                ...state,
                historyList: action.payload || []
            };

        case SET_PENDING_LIST:
            return {
                ...state,
                pendingList: action.payload || []
            };

        default:
            return state;
    }
}

export default userReducer;
