import { CHANGE_FILTER, SET_LIST, SELECT_DEPENDENCY, SET_DEPENDENCY_LIST } from '../actions/tramitsAction';

const initialState = {
    filter: null,
    filterList: [
        { name: "Todas", value: 0 },
        { name: "Radicada", value: 2 },
        { name: "En trámite", value: 3 },
        { name: "Finalizada", value: 4 },
        { name: "Cancelada", value: 7 },
        { name: "Rechazada", value: 9 },
        { name: "Pendiente de pago", value: 1 },
        { name: "Radicada por corrección", value: 5 },
        { name: "Pendiente corrección", value: 8 }
    ],
    tramitsList: [],
    dependencyList: [],
    selectedDependency: null,
    filteredList: []
}

initialState.filteredList = initialState.tramitsList;
initialState.filter = initialState.filterList[0];

const tramitReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                tramitsList: action.payload || [],
                filteredList: (state.filter.value === 0 || !(state.filter)) ? action.payload : action.payload.filter(value => value.est_rad === state.filter.value)
            };

        case SELECT_DEPENDENCY:
            return {
                ...state,
                selectedDependency: action.payload
            };

        case SET_DEPENDENCY_LIST:
            return {
                ...state,
                dependencyList: action.payload || []
            };

        case CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload,
                filteredList: (action.payload.value === 0) ? state.tramitsList : state.tramitsList.filter(value => value.est_rad === action.payload.value)
            };

        default:
            return state;
    }
}

export default tramitReducer;
