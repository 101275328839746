import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Reducers list
import userReducer from './reducers/userReducer'
import tramitsReducer from './reducers/tramitsReducer'

// Create deducer all in one
const allReducers = combineReducers({
    user: userReducer,
    tramits: tramitsReducer
});

// Config Middleware for async actions
const thunkHandler = applyMiddleware(thunk);
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)

// Create the store
const store = createStore(allReducers, composeEnhancers(thunkHandler))

export default store;
