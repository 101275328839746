import React from 'react';

export const getTranscurredDays = (date) => {
    let f1 = new Date(date);
    let f2 = new Date();
    f1 = Date.UTC(f1.getFullYear(), f1.getMonth(), f1.getDate());
    f2 = Date.UTC(f2.getFullYear(), f2.getMonth(), f2.getDate());
    return Math.round((f2 - f1) / (1000 * 60 * 60 * 24));
}

export const getANSData = (date, ansDays, msgAsHTML = true) => {
    let td = getTranscurredDays(date);
    let d = {
        title: "ANS",
        days: ansDays || 0,
        restant: td,
        message: msgAsHTML ? <span>{ansDays || td} días<br /> cumplidos</span> : `${ansDays || td} días cumplidos`,
        color: "#de4b19"
    }

    const setColor = value => Math.round(value / 2) > td ? "#28a745" : (Math.round(value - (value / 3)) < td ? "#de4b19" : "#ff9400")

    if (!(ansDays)) {
        d.title = "Días transcurridos";
        d.restant = 0;
        d.color = setColor(20)
        return d;
    }
    if (td > ansDays) {
        d.restant = td - ansDays;
        d.days = d.restant;
        d.message = msgAsHTML ? <span>Retrasado<br />{d.restant} días</span> : `Retrasado ${d.restant} días`;
    } else if (td < ansDays) {
        d.restant = ansDays - td;
        d.message = msgAsHTML ? <span>Faltan<br />{d.restant} días</span> : `Faltan ${d.restant} días`;
        d.color = setColor(ansDays);
    } else { }
    return d
}

export const ERROR_REPORTING_URL = "https://axis.uninunez.edu.co/apildap/api/log/errorwrite";

/**
 * ============================================================================
 * PRODUCTION ENVIRONMENT CONSTANS
 * ============================================================================
 */

// GOOGLE CLIENT ID  
export const GOOGLE_CLIENT_ID_CURNVIRTUAL = "489784104983-0aj59m5r7qkmqntur44j5st3dg5ror9t.apps.googleusercontent.com";

// API KEY FOR RECAPTCHA 
export const RECAPTCHA_API_KEY = `6LeB0W4UAAAAACXqVNcH-HWzYjcgCw2sY7iMtu0R`;

// BASE URL DOMAIN AXIS 
export const AXIS_BASE_URL = `https://axis.uninunez.edu.co/`;

// API PROCESOS BASE URL
//  export var AXIS_API_URL = `${AXIS_BASE_URL}apiprocesos/api/`;

// Comment
/**
 * ============================================================================
 * DEVELOPMENT ENVIRONMENT CONSTANS
 * ============================================================================
 */

// // API KEY FOR RECAPTCHA 
// export const RECAPTCHA_API_KEY = `6LeB0W4UAAAAACXqVNcH-HWzYjcgCw2sY7iMtu0R`;

// // BASE URL DOMAIN AXIS 
// export const AXIS_BASE_URL = `https://axis.uninunez.edu.co/`;

// API PROCESOS BASE URL
export var AXIS_API_URL = `${AXIS_BASE_URL}apiprocesos/api/`;