import { LOGIN, LOGOUT, UNAUTHORIZED } from '../actions/userAction';

const initialState = {
    userInfo: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    unauthorized: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNAUTHORIZED:
            localStorage.removeItem("user")
            return {
                ...state,
                unauthorized: !!(action.payload),
                userInfo: null
            };

        case LOGIN:
            // window.location.reload();
            // return state;
            return {
                ...state,
                userInfo: action.payload,
                unauthorized: false,
            };

        case LOGOUT:
            localStorage.removeItem("user");
            window.location.reload();
            return state;

        default:
            return state;
    }
}

export default userReducer;
