import { LOGIN, LOGOUT, UNAUTHORIZED } from '../actions/userAction';

const initialState = {
    userInfo: localStorage.getItem("user_ad") ? JSON.parse(localStorage.getItem("user_ad")) : null,
    unauthorized: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UNAUTHORIZED:
            localStorage.removeItem("user_ad");
            return {
                ...state,
                unauthorized: !!(action.payload),
                userInfo: null
            };

        case LOGIN:
            window.location.reload();
            return state;

        case LOGOUT:
            localStorage.removeItem("user_ad");
            window.location.reload();
            return state;

        default:
            return state;
    }
}

export default userReducer;
