import React from 'react';
import ReactDOM from 'react-dom';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import ScreensRoot from "./screens";
import { AXIOS_REQUEST } from './services/axiosService'
import './styles.css'

AXIOS_REQUEST(null, 'get', null, false, "https://axis.uninunez.edu.co/apiaxis/api/mantenimiento/procesos")
.then(resp => {
  if (!!(resp) && typeof resp === 'string') {
    window.location = resp;
  }
}).catch(()=>{})

ReactDOM.render(
  <ScreensRoot />,
  document.getElementById('root')
);