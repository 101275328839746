import { getANSData } from '../../../services/constantsService';
import { AXIOS_REQUEST_AD } from './../../../services/axiosService';

// =============================================================
// CONST ACTIONS
export const SET_PENDING_LIST = "SET_PENDING_LIST";
export const SET_PENDING_QUERY = "SET_PENDING_QUERY";
export const SET_HISTORY_QUERY = "SET_HISTORY_QUERY";
export const SET_HISTORY_LIST = "SET_HISTORY_LIST";
export const HISTORY_LIST_PAGINATION_NUMBER = "HISTORY_LIST_PAGINATION_NUMBER";
export const TRAMIT_LIST_PAGINATION_NUMBER = "TRAMIT_LIST_PAGINATION_NUMBER";

// =============================================================
// ACTIONS
export const setPendingList = payload => ({ payload, type: SET_PENDING_LIST });
export const setPendingQuery = payload => ({ payload, type: SET_PENDING_QUERY });
export const setHistoryQuery = payload => ({ payload, type: SET_HISTORY_QUERY });
export const setHistoryList = payload => ({ payload, type: SET_HISTORY_LIST });
export const setTramitListPaginationNumber = payload => ({ payload, type: TRAMIT_LIST_PAGINATION_NUMBER });
export const setHistoryListPaginationNumber = payload => ({ payload, type: HISTORY_LIST_PAGINATION_NUMBER });

// =============================================================
// ASYNC ACTIONS

/**
 * Get pending tramits list
 * @param {number} page 
 * @param {string} query_search 
 */
export const getPendingTramits = (page = 0, query_search = null, filter = null) => {
    if (!!(query_search)) {
        page += `/${query_search}`;
    }
    if(!!(filter)){
        page += `?estado=${filter}`;
    }
    return (dispatch) => {
        return AXIOS_REQUEST_AD(`radicado/operador/${page}`).then(resp => {
            if (!!(resp.length)) {
                resp = resp.map(i => {
                    i.ans = getANSData(i.fech_rad, i.ans, false);
                    return i;
                })
            }
            dispatch(setPendingList(resp));
        })
    };
};

/**
 * Get pending tramits list
 * @param {number} page 
 */
export const getHistoryTramits = (page = 0, query_search = null) => {
    if (!!(query_search)) {
        page += `/${query_search}`;
    }
    return (dispatch) => {
        return AXIOS_REQUEST_AD(`radicado/operador/historial/${page}`).then(resp => {
            dispatch(setHistoryList(resp));
        })
    };
};

/**
 * Get tramit information
 * @param {int} payload Radicado id
 */
export const getTramitInformation = (payload) => {
    return (dispatch) => {
        return AXIOS_REQUEST_AD(`radicado/operador/detalle/${payload}`)
            .then(resp => {
                return resp[0];
            }).catch(err => {
            });
    };
};

/**
 * Reject tramit 
 * @param {object} payload {motivo: string, codi_rad: int}
 */
export const rejectTramit = (payload) => {
    return (dispatch) => {
        return AXIOS_REQUEST_AD(`radicado/rechazar`, "post", payload, false)
            .then(resp => {
                return resp;
            }).catch(err => {
            });
    };
};


